<template>
  <div id="app">
    <auth-view v-if="!user" />
    <template v-else>
      <admin-layout v-if="user.role === 'admin'">
        <router-view />
      </admin-layout>
      <manager-layout v-else-if="user.role === 'manager'">
        <router-view />
      </manager-layout>
      <customer-layout
        v-else-if="['company-user', 'company-owner'].includes(user.role)"
      >
        <router-view />
      </customer-layout>
      <patient-layout v-else-if="user.role === 'patient'">
        <router-view />
      </patient-layout>
    </template>
    <v-dialog
      v-if="newModalFormVisible"
      :value="newModalFormVisible"
      :width="modalFormComponent.width || 400"
      :content-class="
        (modalFormComponent.contentClass,
        'ma-2 ma-sm-6' || 'rounded-xl ma-2 ma-sm-6')
      "
      @input="cancelNewModalForm()"
    >
      <component
        :is="modalFormComponent.component"
        @confirm="confirmNewModalForm"
        @cancel="cancelNewModalForm"
      />
    </v-dialog>
    <v-footer
      style="
        background-color: #1a2947;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      "
    >
      <img src="/logo.svg" width="30" height="30" style="margin-right: 10px" />
    </v-footer>
  </div>
</template>
<script>
import AuthView from "@/views/AuthView";
import AdminLayout from "@/layouts/AdminLayout";
import ManagerLayout from "@/layouts/ManagerLayout";
import CustomerLayout from "@/layouts/CustomerLayout";
import PatientLayout from "@/layouts/PatientLayout";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PatientLayout,
    CustomerLayout,
    ManagerLayout,
    AdminLayout,
    AuthView,
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("modals", ["newModalFormVisible", "modalFormComponent"]),
  },
  methods: {
    ...mapActions(["doLogin"]),
    ...mapActions(["doGetUser"]),
    ...mapActions("modals", ["cancelNewModalForm", "confirmNewModalForm"]),

    async autoLoginIntoPatient() {
      await this.doLogin({
        email: process.env.VUE_APP_TECH_PATIENT_FOR_SESSION_EMAIL,
        password: process.env.VUE_APP_TECH_PATIENT_FOR_SESSION_PASSWORD,
      });
    },
  },
  beforeMount() {
    if (this.$router.history._startLocation.includes("session-by-url")) {
      this.autoLoginIntoPatient();
    }
    this.doGetUser();
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.border-radius-30px.v-dialog {
  border-radius: 30px;
}

.snackbar .v-snack__content {
  text-align: center;
}

.v-treeview-node .v-treeview-node--leaf {
  margin-left: -30px;
}
</style>
