import BaseService from "./services/BaseService";
import Axios from "axios";

export const SERVER_URI =
  process.env.VUE_APP_SERVER_URI || "http://localhost:3000";

export default class NSApi {
  static _instance = null;

  static get instance() {
    if (this._instance === null) this._instance = new NSApi(this._options);
    return this._instance;
  }

  // eslint-disable-next-line no-unused-vars
  constructor(options = {}) {
    this._axios = options.axios || Axios.create({ withCredentials: true });
    this.users = new BaseService(
      SERVER_URI + "/api/users",
      "user",
      "json",
      this._axios
    );
    this.projects = new BaseService(
      SERVER_URI + "/api/projects",
      "project",
      "json",
      this._axios
    );
  }

  async doLogin(email, password) {
    const r = await this._axios.post(
      SERVER_URI + "/services/auth",
      { username: email, password },
      {
        withCredentials: true,
      }
    );
    return r.data.user;
  }
  async getCurrentUser() {
    try {
      const r = await this._axios.get(SERVER_URI + "/services/auth", {
        withCredentials: true,
      });
      return r.data.user;
    } catch (ex) {
      return undefined;
    }
  }
  async updateCurrentUser(user) {
    const r = await this._axios.put(
      SERVER_URI + `/services/user`,
      { item: user },
      {
        withCredentials: true,
      }
    );

    return r.data.user;
  }
  async doLogout() {
    try {
      await this._axios.post(SERVER_URI + "/services/auth/logout", null, {
        withCredentials: true,
      });
      return true;
    } catch (error) {
      console.error("Logout failed:", error);
      return false;
    }
  }

  async getConfigProperty(propertyName) {
    const r = await this._axios.get(SERVER_URI + "/api/global_config", {
      params: { propertyName },
    });
    return r.data;
  }

  async setConfigProperty(propertyName, propertyValue) {
    const r = await this._axios.post(SERVER_URI + "/api/global_config", {
      propertyName,
      propertyValue,
    });
    return r.data;
  }
}
