<template>
  <v-container fluid fill-height class="align-start py-0 px-0">
    <v-card width="100%" style="box-shadow: none">
      <v-card-text>
        <v-row>
          <v-col
            :cols="object_prefs[v].cols"
            v-for="v in object_fields"
            :key="v"
          >
            <template
              v-if="(object_prefs[v].type || typeof new_value[v]) !== 'boolean'"
            >
              <div class="field-wrapper">
                <div>
                  {{ object_prefs[v].label }}
                </div>
                <component
                  :ref="v"
                  class="text-field"
                  :class="{
                    invalid: getFieldProps(v)?.hasError,
                  }"
                  flat
                  solo
                  outline
                  :hide-details="true"
                  :is="
                    object_prefs[v].component
                      ? object_prefs[v].component
                      : defaultComponent
                  "
                  :key="v"
                  :value="new_value[v]"
                  :type="object_prefs[v].type || typeof new_value[v]"
                  :prepend-inner-icon="object_prefs[v].icon"
                  :disabled="
                    !!object_prefs[v].read_only ||
                    !object_prefs[v].role_visibility?.includes(
                      new_value?.role || value?.role || ''
                    )
                  "
                  :items="object_prefs[v].items"
                  :multiple="object_prefs[v].multiple"
                  :rules="object_prefs[v].rules"
                  @input="
                    (value) => {
                      inputHandler(v, value);
                    }
                  "
                  @change="
                    (value) => {
                      changed = true;
                      new_value = { ...new_value, [v]: value };
                    }
                  "
                  v-bind="object_prefs[v].options"
                />
                <div class="text-field-hint">
                  <div v-if="!getFieldProps(v)?.hasError"></div>
                  <div
                    v-else-if="
                      getFieldProps(v)?.hasError &&
                      !getFieldProps(v)?.messagesToDisplay.length
                    "
                  >
                    {{ getFieldProps(v)?.errorBucket[0] }}
                  </div>
                  <div v-else>
                    {{ $refs[v]?.[0].messagesToDisplay?.[0] }}
                  </div>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row v-for="v in object_switches" :key="v">
          <template
            v-if="(object_prefs[v].type || typeof new_value[v]) === 'boolean'"
          >
            <v-switch
              :key="v"
              :label="object_prefs[v].label"
              :input-value="new_value[v]"
              :value="new_value[v]"
              :disabled="
                !object_prefs[v].role_visibility.includes(
                  new_value.role || value.role || ''
                )
              "
              @change="
                (value) => {
                  inputHandler(v, value);
                }
              "
            />
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!no_editing">
        <v-btn
          class="white--text"
          style="
            background-color: #d86568;
            width: 150px;
            height: 50px;
            border-radius: 50px;
          "
          :disabled="isFieldsInvalid"
          @click="doSave"
        >
          Сохранить
        </v-btn>
        <slot />
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { VTextField } from "vuetify/lib/components";
import { mapGetters } from "vuex";

export default {
  name: "PropertyForm",
  props: {
    value: Object,
    no_editing: Boolean,
    simulateReactivity: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["input"],
  data() {
    return {
      new_value: {},
      changed: false,
      all_roles: [
        "admin",
        "company-owner",
        "company-user",
        "manager",
        "tech-user",
        "",
      ], // пустое поле тоже
    };
  },
  mounted() {
    if (this.simulateReactivity)
      Object.keys(this.value).forEach((key) => {
        this.$watch(
          () => this.value[key],
          (newVal) => {
            this.new_value[key] = newVal;
          }
        );
      });
    this.new_value = { ...this.value };
  },
  computed: {
    defaultComponent() {
      return VTextField;
    },
    object_keys() {
      return [
        ...new Set([
          ...Object.keys(this.new_value),
          ...Object.keys(this.fields),
        ]),
      ]
        .filter((k) => (this.fields[k] || { visible: false }).visible)
        .sort((a, b) => this.fields[a]?.order - this.fields[b]?.order);
    },
    object_fields() {
      // return items with type not boolean from object_keys
      return this.object_keys.filter((k) => this.fields[k]?.type !== "boolean");
    },
    object_switches() {
      // return items with type boolean from object_keys
      return this.object_keys.filter((k) => this.fields[k]?.type === "boolean");
    },

    object_prefs() {
      return this.object_keys.reduce((map, obj) => {
        map[obj] = {
          label: this.fields[obj]?.label || obj,
          icon: this.fields[obj]?.icon,
          component: this.fields[obj]?.component,
          read_only: this.fields[obj]?.read_only,
          type: this.fields[obj]?.type ?? "string",
          cols: this.fields[obj]?.twoColumns ? 12 : 6,
          items: this.fields[obj]?.items,
          multiple: this.fields[obj]?.multiple,
          options: this.fields[obj]?.options,
          rules: this.rulesHandler(obj),
        };
        if (!this.fields[obj]?.role_visibility)
          map[obj].role_visibility = this.all_roles;
        else map[obj].role_visibility = this.fields[obj]?.role_visibility;
        return map;
      }, {});
    },
    isFieldsInvalid() {
      if (this.changed === false) return true;

      for (let field in this.object_prefs) {
        const fieldProps = this.getFieldProps(field);
        if (fieldProps && fieldProps.hasError) return true;
      }
      return false;
    },
  },
  methods: {
    ...mapGetters("modals", ["modalFormComponent"]),
    ...mapGetters("modals", ["newModalFormVisible"]),
    doSave() {
      this.$emit("input", { ...this.new_value });
    },
    inputHandler(field_name, value) {
      if (field_name === "phone") {
        value = this.formatPhoneNumber(value);
      }
      if (field_name === "role")
        // Если поменялась роль, то все зависящие от роли сбрасываются до default
        for (let field in this.object_prefs) {
          if (!this.object_prefs[field].role_visibility.includes(value))
            this.new_value[field] = this.object_prefs[field].default ?? null;
        }
      this.changed = true;
      this.new_value = { ...this.new_value, [field_name]: value };
    },
    formatPhoneNumber(value) {
      // Очищаем номер от лишних символов
      const digits = value.replace(/\D/g, "");
      let formattedPhoneNumber = "";

      // Наложение "маски"
      if (digits.length >= 1) {
        formattedPhoneNumber = `+${digits[0]}`;
      }
      if (digits.length >= 2) {
        formattedPhoneNumber += ` (${digits.slice(1, 4)}`;
      }
      if (digits.length >= 5) {
        formattedPhoneNumber += `) ${digits.slice(4, 7)}`;
      }
      if (digits.length >= 8) {
        formattedPhoneNumber += ` ${digits.slice(7, 9)}`;
      }
      if (digits.length >= 10) {
        formattedPhoneNumber += ` ${digits.slice(9, 11)}`;
      }

      return formattedPhoneNumber;
    },
    getFieldProps(field_name) {
      return this.$refs[field_name]?.[0];
    },
    rulesHandler(field_name) {
      if (field_name === "password") {
        const insideVDialog = this.newModalFormVisible();
        return !this.new_value[field_name] && insideVDialog
          ? undefined
          : this.fields[field_name]?.rules;
      }
      return this.fields[field_name]?.rules;
    },
  },
};
</script>

<style scoped lang="scss">
.text-field {
  border: 1px solid #23919e;
}
.text-field.invalid {
  border: 1px solid #d86568;
}
.text-field-hint {
  height: 20px;
  color: #d86568;
}
</style>
